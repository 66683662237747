:root {
  --primary-color: rgba(96, 205, 255, 1);
  --primary-color-10: rgba(96, 205, 255, 0.1);
  --primary-color-20: rgba(96, 205, 255, 0.2);
  --primary-color-30: rgba(96, 205, 255, 0.3);
  --primary-color-40: rgba(96, 205, 255, 0.4);
  --primary-color-50: rgba(96, 205, 255, 0.5);
  --primary-color-60: rgba(96, 205, 255, 0.6);
  --primary-color-70: rgba(96, 205, 255, 0.7);
  --primary-color-80: rgba(96, 205, 255, 0.8);
  --primary-color-90: rgba(96, 205, 255, 0.9);

  --primary-color-accent: rgba(35, 117, 155, 1);
  --primary-color-accent-10: rgba(35, 117, 155, 0.1);
  --primary-color-accent-20: rgba(35, 117, 155, 0.2);
  --primary-color-accent-30: rgba(35, 117, 155, 0.3);
  --primary-color-accent-40: rgba(35, 117, 155, 0.4);
  --primary-color-accent-50: rgba(35, 117, 155, 0.5);
  --primary-color-accent-60: rgba(35, 117, 155, 0.6);
  --primary-color-accent-70: rgba(35, 117, 155, 0.7);
  --primary-color-accent-80: rgba(35, 117, 155, 0.8);
  --primary-color-accent-90: rgba(35, 117, 155, 0.9);

  --primary-color-greyed: rgba(49, 52, 54, 1);
  --primary-color-greyed-10: rgba(49, 52, 54, 0.1);
  --primary-color-greyed-20: rgba(49, 52, 54, 0.2);
  --primary-color-greyed-30: rgba(49, 52, 54, 0.3);
  --primary-color-greyed-40: rgba(49, 52, 54, 0.4);
  --primary-color-greyed-50: rgba(49, 52, 54, 0.5);
  --primary-color-greyed-60: rgba(49, 52, 54, 0.6);
  --primary-color-greyed-70: rgba(49, 52, 54, 0.7);
  --primary-color-greyed-80: rgba(49, 52, 54, 0.8);
  --primary-color-greyed-90: rgba(49, 52, 54, 0.9);
}
