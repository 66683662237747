/* You can add global styles to this file, and also import other style files */
@import url("https://rsms.me/inter/inter.css");
@import "variables";

html,
body {
  margin: 0;
  min-width: 100%;
  background-color: #fafafa;
}

html,
body {
  font-family: "Inter", sans-serif;
  color: var(--primary-color-greyed);
}

@supports (font-variation-settings: normal) {
  html,
  body {
    font-family: "Inter var", sans-serif;
    color: var(--primary-color-greyed);
  }
}
